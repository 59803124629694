import { FetchResult, DashboardFilters, APISchemaUpdate } from 'src/types/TApiQueries';
import { config } from 'src/utils/config';
import { getStandardHeaders, getUploadHeaders, performFetchWithLogging } from '../utils/helpers';
import { filter } from 'lodash';

export async function getSearch(filtersData: DashboardFilters, accessToken: string): Promise<FetchResult<any>> {
  const url = `${config.hostUrl}/api/v1/assets/search`;
  const { page, hash, ...filters } = filtersData; // do not send unneeded properties
  
  if (filters?.file_name) {
    let inputValue = filters?.file_name;

    while (inputValue.endsWith('*')) {
      inputValue = inputValue.slice(0, -1);
    }
    // Append a single asterisk if the input is not empty
    const formattedValue = inputValue ? `${inputValue}*` : '';
    filters.file_name = formattedValue;
  }
  delete filters?.["filters"]
  delete filters["face"]

  if (filters?.content_type) {
    let contentType = filters?.content_type;
    if (filters?.custom_content_input !== '') {
      contentType = filters?.custom_content_input || '';
    }

    const formattedField = contentType ? `${contentType}*` : '*';

    filters.content_type = formattedField;
  }
  delete filters?.custom_content_input;

  const payload = JSON.stringify(filters);

  return performFetchWithLogging(url, {
    method: 'POST',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
    body: payload,
  });
}

export async function getSearchByHash(
  { hash, page }: DashboardFilters,
  accessToken: string,
): Promise<FetchResult<any>> {
  const url = `${config.hostUrl}/api/v1/assets/search/${hash}/${page}`;

  return performFetchWithLogging(url, {
    method: 'GET',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
  });
}

// Function to transform the response
function transformResponse(response: any): any {
  return {
    status: response.status || "OK",
    version: response.version || 1,
    total_files: response.hits || 0,
    search_result_id: response.search_id || "",
    page: response.page || 1,
    total_pages: Math.ceil((response.hits || 0) / (response.limit_per_page || 1)),
    result: response.data?.matches || [],
    extended_result: response.data?.extended_result || {},
  };
}

export async function getFaceSearch(filtersData: any, accessToken: string): Promise<FetchResult<any>> {
  const url = `${config.hostUrl}/api/v1/ml/people/facesearch`;
  const { face, limit_per_page = 30 } = filtersData; // Only required data



  // Create FormData object to send multipart/form-data
  const formData = new FormData();
  formData.append('save_search', 'true');
  formData.append('include_groups', 'false');
  formData.append('extended_result', 'true');
  formData.append('strictness', '9');
  formData.append('limit_per_page', limit_per_page.toString());
  // Check if filters exist and append as a JSON string
  if (filtersData?.filters) {
    formData.append('filters', JSON.stringify(filtersData.filters));
  }

  // Function to convert a base64 string to Blob
  function base64ToBlob(base64: string, contentType = '', sliceSize = 512): Blob {
    const byteCharacters = atob(base64); // Decode base64 string
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i); // Get char codes of slice
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray); // Collect into byte array
    }

    return new Blob(byteArrays, { type: contentType });
  }

  // Assuming the image data comes as 'data:image/jpeg;base64,...'
  const base64Data = face.split(',')[1]; // Base64 image data without the prefix
  const contentType = face.split(',')[0].split(':')[1].split(';')[0]; // Get content type (e.g., 'image/jpeg')

  // Convert base64 to Blob and append to FormData as binary
  const imageBlob = base64ToBlob(base64Data, contentType);
  formData.append('image', imageBlob, 'image.jpg'); // Add image file to form


  // Perform fetch request and transform response before returning
  const rawResponse = await performFetchWithLogging(url, {
    method: 'POST',
    headers: {
      Authorization: accessToken,
    },
    credentials: 'include',
    body: formData,
  });

  // Transform the response before returning
  return transformResponse(rawResponse);

}



export async function getFaceSearchByHash(
  { hash, page, limit_per_page = 30  }: any,
  accessToken: string,
): Promise<FetchResult<any>> {
  const url = `${config.hostUrl}/api/v1/ml/people/facesearch?page=${page}&search_id=${hash}&limit_per_page=${limit_per_page}&extended_result=true`;

  const rawResponse = await performFetchWithLogging(url, {
    method: 'GET',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
  });
  return transformResponse(rawResponse)
}

export async function getAssetDetails(assetId: string, accessToken: string): Promise<FetchResult<any>> {
  const url = `${config.hostUrl}/api/v1/assets/${assetId}/data`;

  return performFetchWithLogging(url, {
    method: 'GET',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
  });
}

export async function getAssetMediaUrl(
  assetId: string,
  key: string,
  accessId: string,
  deliveryMode: 'display' | 'download',
  accessToken: string,
): Promise<FetchResult<any>> {
  const url = `${config.hostUrl}/api/v1/assets/${assetId}/get_link?key=${key}&expiry=${config.JWTExpiration}&delivery=${deliveryMode}&accessId=${accessId}`;

  return performFetchWithLogging(
    url,
    {
      method: 'GET',
      headers: getStandardHeaders(accessToken),
      credentials: 'include',
    },
    'text',
  );
}

export async function getAssetVisionTags(assetId: string, accessToken: string): Promise<FetchResult<any>> {
  const url = `${config.hostUrl}/api/v1/assets/${assetId}/addons/image_analysis`;

  return performFetchWithLogging(url, {
    method: 'GET',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
  });
}

export async function updateAssetMetadata(
  assetId: string,
  data: APISchemaUpdate<any>,
  accessToken: string,
): Promise<FetchResult<any>> {
  const url = `${config.hostUrl}/api/v1/assets/${assetId}/data/update`;
  delete data?.data?._filespin_fr_count
  delete data?.data?._filespin_fr_face_ids
  delete data?.data?._filespin_autotag_labels
  delete data?.data?._filespin_scene_labels
  delete data?.data?._filespin_emotion_labels
  delete data?.data?._filespin_pose_labels
  const payload:any = JSON.stringify(data);
  return performFetchWithLogging(url, {
    method: 'POST',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
    body: payload,
  });
}

export async function updateAssetMedia(assetId: string, data: any, accessToken: string): Promise<FetchResult<any>> {
  const form = new FormData();
  form.append('file', data.file, data.filename);

  const url = `${config.hostUrl}/api/v1/assets/${assetId}/content/original/replace`;

  return performFetchWithLogging(url, {
    method: 'POST',
    headers: getUploadHeaders(accessToken),
    credentials: 'include',
    body: form,
  });
}

export async function updateAssetBatchReprocess(data: any, accessToken: string): Promise<FetchResult<any>> {
  const url = `${config.hostUrl}/api/v1/assets/content/original/reprocess`;
  const payload = JSON.stringify(data);

  return performFetchWithLogging(
    url,
    {
      method: 'POST',
      headers: getStandardHeaders(accessToken),
      credentials: 'include',
      body: payload,
    },
    'text',
  );
}

export async function getBatchAssetLinks(
  data: any,
  accessToken: string,
  content_key: string,
  expiry: number,
  is_expiry_max: boolean,
  delivery_mode: string,
  accessId: string,
): Promise<FetchResult<any>> {
  const url = `${config.hostUrl}/api/v1/assets/get_link?key=${content_key}&expiry=${
    is_expiry_max ? 'MAX' : expiry
  }&delivery=${delivery_mode}&accessId=${accessId}`;

  const payload = JSON.stringify(data);

  return performFetchWithLogging(
    url,
    {
      method: 'POST',
      headers: { ...getStandardHeaders(accessToken) },
      credentials: 'include',
      body: payload,
    },
    'json',
  );
}

export async function getJobs(
  limit: number,
  offset: number,
  accessToken: string,
  status: string,
  start_time: string,
  end_time: string,
) {
  let url = `${config.hostUrl}/api/v1/jobs?limit=${limit}&offset=${offset}`;
  if (status !== 'ALL' && status !== null) {
    url += `&status=${status}`.replace('PROCESSING', 'IN_PROGRESS');
  }
  if (start_time !== '' && start_time !== 'YYYY-MM-DDThh:mm:ssZ') {
    url += `&start_time=${start_time}:00Z`;
  }
  if (end_time !== '' && end_time !== 'YYYY-MM-DDThh:mm:ssZ') {
    url += `&end_time=${end_time}:00Z`;
  }
  return performFetchWithLogging(
    url,
    {
      method: 'GET',
      headers: { ...getStandardHeaders(accessToken) },
      credentials: 'include',
    },
    'json',
  );
}

export async function getJobStatus(jobId: string, accessToken: string) {
  const url = `${config.hostUrl}/api/v1/jobs/${jobId}`;
  return performFetchWithLogging(
    url,
    {
      method: 'GET',
      headers: { ...getStandardHeaders(accessToken) },
      credentials: 'include',
    },
    'json',
  );
}

export async function batchCDNPrefetch(data: any, accessToken: string) {
  const url = `${config.hostUrl}/api/v1/cdn/prefetch`;
  const payload = JSON.stringify(data);
  return performFetchWithLogging(
    url,
    {
      method: 'POST',
      headers: { ...getStandardHeaders(accessToken) },
      credentials: 'include',
      body: payload,
    },
    'text',
  );
}

export async function batchCDNPurge(data: any, accessToken: string) {
  const url = `${config.hostUrl}/api/v1/cdn/purge`;
  const payload = JSON.stringify(data);
  return performFetchWithLogging(
    url,
    {
      method: 'POST',
      headers: { ...getStandardHeaders(accessToken) },
      credentials: 'include',
      body: payload,
    },
    'text',
  );
}

export async function createNewAsset(
  assetId: string,
  data: any,
  accessToken: string,
  metadata: any,
  includeMetadata: boolean,
): Promise<FetchResult<any>> {
  const form = new FormData();
  form.append('file', data.file, data.filename);
  const url = `${config.hostUrl}/api/v1/assets/new/content/original/upload`;
  const response: any = await fetch(url, {
    method: 'POST',
    headers: getUploadHeaders(accessToken),
    body: form,
  });
  const temp: any = await response.json();
  const newAssetId = temp.files[0].id;
  const metadataurl = `${config.hostUrl}/api/v1/assets/${newAssetId}/data/update`;

  let filteredMetadata: { [key: string]: any } = {};

  if (!includeMetadata) {
    filteredMetadata = {};
  } else {
    Object.keys(metadata).forEach((key) => {
      if (!key.startsWith('_filespin')) {
        filteredMetadata[key] = metadata[key];
      }
    });
  }
  filteredMetadata['_filespin_source_id'] = assetId;
  const jsonBody = JSON.stringify({
    data: filteredMetadata,
    mode: 'REPLACE',
  });
  const sleep = () => new Promise((r) => setTimeout(r, 2000));
  for (let i = 0; i < 5; i++) {
    await sleep();
    const metadataresponse = await fetch(metadataurl, {
      method: 'POST',
      headers: getUploadHeaders(accessToken),
      body: jsonBody,
    });
    if (metadataresponse.ok) {
      break;
    }
  }
}
//TODO: conversionName must be supplied from caller
export async function updateAssetConversionMedia(
  assetId: string,
  conversionName: string,
  data: any,
  accessToken: string,
): Promise<FetchResult<any>> {
  const form = new FormData();
  form.append('file', data.file, data.filename);

  const url = `${config.hostUrl}/api/v1/assets/${assetId}/content/${conversionName}`;

  return performFetchWithLogging(url, {
    method: 'POST',
    headers: getUploadHeaders(accessToken),
    credentials: 'include',
    body: form,
  });
}

export async function purgeAssets(accessToken: string): Promise<FetchResult<any>> {
  const url = `${config.hostUrl}/api/v1/assets/trash/purge`;

  return performFetchWithLogging(url, {
    method: 'DELETE',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
  });
}

export async function deleteAsset(assetId: string, accessToken: string): Promise<FetchResult<any>> {
  const url = `${config.hostUrl}/api/v1/assets/${assetId}/delete`;

  const payload = JSON.stringify({ keys: ['original'] });

  return performFetchWithLogging(url, {
    method: 'POST',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
    body: payload,
  });
}

export async function restoreAsset(assetId: string, accessToken: string): Promise<FetchResult<any>> {
  const url = `${config.hostUrl}/api/v1/assets/trash/${assetId}/undelete`;

  const payload = JSON.stringify({ keys: ['original'] });

  return performFetchWithLogging(url, {
    method: 'POST',
    headers: getStandardHeaders(accessToken),
    credentials: 'include',
    body: payload,
  });
}

//
