import { useSettingsQuery } from 'src/api/queries/userQueries';
import {
  AdminSettingsSection,
  AdminSettingsSidebar,
  Button,
  Checkbox,
  ColorPicker,
  Input,
  PageTitle,
  Paper,
  SaveChangesBar,
  Select,
  SettingsNavigation,
  Table,
} from 'src/components';
import styles from './styles.module.scss';
import { useAtom } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import ReactSwitch from 'react-switch';
import { VideoTranscodes } from './components/VideoTranscodes';
import { videoSettingsAtom } from 'src/shared/settings/video';
import { ACTION_TYPES } from 'src/shared/settings/base';





type FormData = {
  authorized_domains: string;
  dropbox_app_key: string;
  dropbox_app_secret: string;
  dropbox_redirect_url: string;
};

export const VideoSettings = () => {
  const { isLoading } = useSettingsQuery();

  const [videoSettings, updateVideoSettings] = useAtom(videoSettingsAtom);

  const videoKeys = useMemo(() => (videoSettings ? Object.keys(videoSettings.transcodes) : []), [videoSettings]);

  const videoPreview = useMemo(() => videoSettings?.preview, [videoSettings]);

  const videoStoryBoard = useMemo(() => videoSettings?.thumbnails.storyboard, [videoSettings]);

  const handleThumbnailUpdate = (name: string, value: number) =>
    updateVideoSettings({
      type: ACTION_TYPES.UPDATE_STATE,
      payload: { thumbnails: { video_default: { [name]: value } } },
    });

  const handleStoryboardUpdate = (name: string, value: string | boolean | number) =>
    updateVideoSettings({
      type: ACTION_TYPES.UPDATE_STATE,
      payload: { thumbnails: { storyboard: { [name]: value } } },
    });

  const handlePreviewUpdate = (name: string, value: any) =>
    updateVideoSettings({
      type: ACTION_TYPES.UPDATE_STATE,
      payload: { preview: { [name]: value } },
    });

  if (isLoading) {
    return null;
  }

  return (
    <div className={styles.pageWrapper}>
      <SettingsNavigation />
      <SaveChangesBar />

      <div className={styles.container}>
        <div className={styles.sidebar}>
          <AdminSettingsSidebar
            items={[
              {
                key: 'testTranscodeMode',
                label: 'Test Transcode mode',
              },
              {
                key: 'thumbnailSize',
                label: 'Video Thumbnail Size',
              },
              {
                key: 'videoPreview',
                label: 'Video Preview',
              },
              {
                key: 'videoTranscodes',
                label: 'Video Transcodes',
              },
            ]}
          />
        </div>
        <div className={styles.content}>
          <AdminSettingsSection title="Test transcode mode">
            <Paper className={styles.onDemandImages}>
              <ReactSwitch
                checked={videoSettings.video_transcode_test_mode}
                onChange={(checked: boolean) =>
                  updateVideoSettings({
                    type: ACTION_TYPES.UPDATE_STATE,
                    payload: { video_transcode_test_mode: checked },
                  })
                }
                checkedIcon={false}
                uncheckedIcon={false}
                onColor="#2e53e3"
              />

              <p>Limit all video transcoding to 5 seconds (Free)</p>
            </Paper>
          </AdminSettingsSection>
          <AdminSettingsSection title="Video thumbnail size">
            <Table headers={['Width (PX)', 'Height (PX)']}>
              <tr>
                <td>
                  <Input
                    variant="secondary"
                    value={videoSettings.thumbnails.video_default.width}
                    type="number"
                    onChange={(value) => handleThumbnailUpdate('width', parseInt(value))}
                  />
                </td>
                <td>
                  <Input
                    variant="secondary"
                    type="number"
                    value={videoSettings.thumbnails.video_default.height}
                    onChange={(value) => handleThumbnailUpdate('height', parseInt(value))}
                  />
                </td>
              </tr>
            </Table>
          </AdminSettingsSection>
          {videoSettings.thumbnails.storyboard && (
            <AdminSettingsSection title="Storyboard images">
              <h4 className={styles.sectionSubTitle}>Type</h4>
              <Paper>
                <Select
                  value={videoStoryBoard.mode}
                  onChange={(value: string) => handleStoryboardUpdate('mode', value)}
                  options={[
                    {
                      label: 'Extract N images over entire video ',
                      value: 'number',
                    },
                    {
                      label: 'Extract image every Nth second ',
                      value: 'second',
                    },
                    {
                      label: 'Extract all Keyframes as images ',
                      value: 'keyframes',
                    },
                  ]}
                />
              </Paper>

              <Table
                headers={[
                  'value',
                  'width (px)',
                  'height (px)',
                  'mode',
                  'padding color',
                  'upscale',
                  'public',
                  'create on upload',
                ]}
              >
                <tr>
                  <td>
                    <Input 
                      variant="secondary"
                      value={videoSettings.thumbnails.storyboard.mode_value}
                      onChange={(value) => handleStoryboardUpdate('mode_value', value === "" ? 5 : parseInt(value))}

                    />
                  </td>
                  <td>
                    <Input
                      variant="secondary"
                      value={videoSettings.thumbnails.storyboard.width}
                      onChange={(value) =>  handleStoryboardUpdate('width', value === "" ? 1280 : parseInt(value))}
                    />
                  </td>
                  <td>
                    <Input
                      variant="secondary"
                      value={videoSettings.thumbnails.storyboard.height}
                      onChange={(value) => handleStoryboardUpdate('height', value === "" ? 720 : parseInt(value))}
                    />
                  </td>
                  <td>
                    <Select
                      value={videoSettings.thumbnails.storyboard.aspect}
                      onChange={(value) => handleStoryboardUpdate('aspect', value)}
                      className={styles.centeredSelect}
                      options={[
                        { label: 'Pad', value: 'pad' },
                        { label: 'Preserve', value: 'preserve' },
                        { label: 'Scale', value: 'scale' },
                        { label: 'Crop', value: 'crop' },
                      ]}
                    />
                  </td>
                  <td>
                    <ColorPicker
                      value={videoSettings.thumbnails.storyboard.padding}
                      onChange={(color) => handleStoryboardUpdate('padding', color)}
                    />
                  </td>
                  <td>
                    <Checkbox
                      className={styles.centeredCheckbox}
                      checked={videoSettings.thumbnails.storyboard.upscale}
                      onChange={(e) => handleStoryboardUpdate('upscale', e.target.checked)}
                    />
                  </td>
                  <td>
                    <Checkbox
                      className={styles.centeredCheckbox}
                      checked={videoSettings.thumbnails.storyboard.public}
                      onChange={(e) => handleStoryboardUpdate('public', e.target.checked)}
                    />
                  </td>
                  <td>
                    <Checkbox
                      className={styles.centeredCheckbox}
                      checked={videoSettings.thumbnails.storyboard.enabled}
                      onChange={(e) => handleStoryboardUpdate('enabled', e.target.checked)}
                    />
                  </td>
                </tr>
              </Table>
            </AdminSettingsSection>
          )}
          <AdminSettingsSection title="Video Preview">
            <h4 className={styles.sectionSubTitle}>Preset</h4>
            <Paper>
              <Select
                value={videoSettings.preview.video_preview_preset}
                onChange={(value: string) => handlePreviewUpdate('video_preview_preset', value)}
                options={videoKeys.map((key) => ({ label: key, value: key }))}
              />
            </Paper>

            <Table
              headers={[
                'Start at (second)',
                'Length (seconds)',
                'mode',
                'padding color',
                'upscale',
                'public',
                'create on upload',
              ]}
            >
              <tr>
                <td>
                  <Input
                    variant="secondary"
                    value={videoPreview.video_preview_start}
                    onChange={(value: string) => handlePreviewUpdate('video_preview_start', value === "" ? 0 : parseInt(value))}
                  />
                </td>
                <td>
                  <Input
                    variant="secondary"
                    value={videoPreview.video_preview_length}
                    onChange={(value: string) => handlePreviewUpdate('video_preview_length', value === "" ? 0 : parseInt(value))}
                  />
                </td>
                <td>
                  <Select
                    value={videoPreview.video_preview_aspect}
                    className={styles.centeredSelect}
                    onChange={(value: string) => handlePreviewUpdate('video_preview_aspect', value)}
                    options={[
                      { label: 'Pad', value: 'pad' },
                      { label: 'Preserve', value: 'preserve' },
                      { label: 'Scale', value: 'scale' },
                      { label: 'Crop', value: 'crop' },
                    ]}
                  />
                </td>
                <td>
                  <ColorPicker
                    value={videoPreview.video_preview_padding}
                    onChange={(color) => handlePreviewUpdate('video_preview_padding', color)}
                  />
                </td>
                <td>
                  <Checkbox
                    className={styles.centeredCheckbox}
                    checked={videoPreview.video_preview_upscale}
                    onChange={(e) => handlePreviewUpdate('video_preview_upscale', e.target.checked)}
                  />
                </td>
                <td>
                  <Checkbox
                    className={styles.centeredCheckbox}
                    checked={videoPreview.video_preview_public}
                    onChange={(e) => handlePreviewUpdate('video_preview_public', e.target.checked)}
                  />
                </td>
                <td>
                  <Checkbox
                    className={styles.centeredCheckbox}
                    checked={videoPreview.video_preview_enabled}
                    onChange={(e) => handlePreviewUpdate('video_preview_enabled', e.target.checked)}
                  />
                </td>
              </tr>
            </Table>
          </AdminSettingsSection>
          <VideoTranscodes />
        </div>
      </div>
    </div>
  );
};
